import React, { Component } from "react";
import {
  Checkbox,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import Popup from "reactjs-popup";
import * as constants from "../../constants";
import { getRepos, signUp } from "../../libs/API";

class CreateUserPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      userEmail: "",
      userPassword: "",
      confirmPassword: "",
      errorMessage: "",
      addingUser: false,
      availableRepos: [],
      selectedRepos: [],
    };
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showCreateUserPopup });

    getRepos().then((data) => {
      //console.log(`Repos: ${JSON.stringify(data)}`);
      this.setState({ availableRepos: data });
    });
  }

  closePopup() {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value, errorMessage: "" });
  };

  handleRepoSelection = (e) => {
    const repoNodeId = e.target.id;
    const checked = document.getElementById(repoNodeId).checked;
    let { availableRepos, selectedRepos } = this.state;

    if (checked) {
      //add to selectedRepos
      const selectedRepo = availableRepos.filter(
        (r) => r.nodeId.toString() === repoNodeId
      )[0];
      selectedRepos.push(selectedRepo);
    } else {
      //remove from selectedRepos
      selectedRepos = selectedRepos.filter(
        (r) => r.nodeId.toString() !== repoNodeId
      );
    }

    this.setState({ selectedRepos });
  };

  handleAddUser = async (e) => {
    this.setState({ successMessage: "" });
    e.preventDefault();
    if (!this.state.confirmPassword.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/)) {
      this.setState({
        errorMessage: "Minimum 8 Characters including 1 number.",
      });
      return;
    }
    if (this.state.userPassword !== this.state.confirmPassword) {
      this.setState({ errorMessage: constants.PASSWORD_MATCH_INVALID });
      return;
    }
    this.setState({ addingUser: true });
    const userDetails = {
      userEmail: this.state.userEmail,
      userPassword: this.state.userPassword,
      repos: this.state.selectedRepos,
    };
    signUp(userDetails).then(async (data) => {
      let dataText = "";
      if (data.status === 200) {
        dataText = await data.text();
        let addNewRow = {
          userEmail: dataText.split("|")[1],
          groups: [],
          username: dataText.split("|")[0],
        };
        this.props.handleNewRowInUserList(addNewRow);
      } else {
        dataText = await data.text();
      }
      this.setState({ addingUser: false });
      this.closePopup();
      alert(dataText);
    });
  };

  renderCreateUser() {
    return (
      <div>
        <h4>Add New User</h4>
        <hr />
        <div style={{ float: "left", width: "25%" }}>
          <form onSubmit={this.handleAddUser}>
            <FormGroup controlId="userEmail">
              <ControlLabel>Email:</ControlLabel>
              <FormControl
                componentClass="input"
                onChange={this.handleChange}
                type="input"
                required
                placeholder="Email"
                autoComplete="false"
              ></FormControl>
            </FormGroup>
            <FormGroup controlId="userPassword">
              <ControlLabel>Password:</ControlLabel>
              <FormControl
                componentClass="input"
                type="password"
                onChange={this.handleChange}
                required
                minLength={8}
                placeholder="Password"
                autoComplete="new-password"
              ></FormControl>
            </FormGroup>
            <FormGroup controlId="confirmPassword">
              <ControlLabel>Confirm Password:</ControlLabel>
              <FormControl
                componentClass="input"
                type="password"
                onChange={this.handleChange}
                required
                minLength={8}
                placeholder="Confirm Password"
                autoComplete="new-password"
              ></FormControl>
            </FormGroup>
            <FormGroup>
              <Button
                bsClass="btn btn-outline-secondary"
                type="submit"
                disabled={
                  //disable the button if no repos are selected, or the app is currently processing a request
                  this.state.selectedRepos.length < 1 || this.state.addingUser
                }
              >
                Add User
              </Button>
            </FormGroup>
            <span className="errorMessage">{this.state.errorMessage}</span>
          </form>
        </div>
        <div>
          <FormGroup>
            <ControlLabel>Grant access to these repositories:</ControlLabel>
            <div style={{ height: "300px", overflow: "auto" }}>
              {this.state.availableRepos
                .sort((x, y) => (x.owner < y.owner ? -1 : 1))
                .map((repo, i) => (
                  <Checkbox
                    key={i}
                    id={repo.nodeId}
                    onChange={this.handleRepoSelection}
                  >
                    {"   " + repo.owner + " / " + repo.folderName}
                  </Checkbox>
                ))}
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Popup
          contentStyle={{
            width: "60%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            {this.renderCreateUser()}
          </div>
        </Popup>
      </div>
    );
  }
}

export default CreateUserPopup;
