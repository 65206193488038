import React, { Fragment, Component } from "react";
import { Button, FormControl, Row, Col, ControlLabel } from "react-bootstrap";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Header from "../header";
import LoaderButton from "../LoaderButton";
import {
  deleteRepository,
  addRepoData,
  getRepositories,
  updateRepoData,
} from "../../libs/API";
import "./managerepository.css";

class ManageRepository extends Component {
  gridRepoRef = null;
  constructor(props) {
    super(props);
    this.state = {
      repoData: [],
      repositoryOwner: "",
      folderName: "",
      s3Path: "",
      message: "",
      repositoryId: 0,
      isLoading: false,
    };
    // console.log("ManageRepository", props.props.token);
  }
  async componentDidMount() {
    await this.getAllRepositories();
  }

  getAllRepositories = async () => {
    getRepositories().then((data) => {
      if (data) {
        this.setState({ repoData: data });
      }
    });
  };

  addNewRow = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      let newRow = {
        repositoryOwner: this.state.repositoryOwner,
        folderName: this.state.folderName,
        directoryPath: this.state.s3Path,
        repositoryId: this.state.repositoryId,
      };

      const data = await addRepoData(newRow);

      if (data.errorMessage.length === 0) {
        newRow = data;
        this.gridRepoRef.table.addRow(newRow, false);
      } else {
        this.setState({ message: data.errorMessage });
      }
    } catch (error) {
      // Handle any errors that occur during the execution
      console.error("An error occurred:", error);
    } finally {
      this.setState({ isLoading: false });
      document.getElementById("repoOwner").value = "";
      document.getElementById("folderName").value = "";
    }
  };

  showAlert = (cell) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let repositoryId = cell.getRow().getData().repositoryId;
            deleteRepository(repositoryId).then((data) => {
              if (data.status === 200) {
                cell.getRow().delete();
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  updateData = async (e, cell) => {
    e.preventDefault();
    if (e.target.innerText === "Update") {
      const rowData = cell.getRow().getData();
      updateRepoData(rowData).then((data) => {
        if (data.status === 200) {
          alert("Updated");
        }
      });
    } else if (e.target.innerText === "Delete") {
      this.showAlert(cell);
    }
  };

  renderRepoTabulator() {
    let openButton = function () {
      let button =
        "<div><button type='button'class='btn btn-outline-secondary btn-sm updatedeletebuttons' >Update</button> <button type='button'class='btn btn-outline-secondary btn-sm updatedeletebuttons' >Delete</button></div>";
      return button;
    };

    return (
      <div className="managerepo-content">
        <h4> Manage Repositories</h4>
        <br />
        <ReactTabulator
          id="table"
          style={{
            height: "74%",
            marginLeft: "5px",
          }}
          columns={[
            {
              title: "Repository Owner",
              field: "repositoryOwner",
              editor: "input",
              headerFilter: "input",
            },
            {
              title: "Repository Name",
              field: "folderName",
              editor: "input",
              headerFilter: "input",
            },
            {
              title: "S3 Path",
              field: "s3Path",
              headerFilter: "input",
            },
            {
              title: "",
              headerSort: false,
              formatter: openButton,
              hozAlign: "center",
              cellClick: (e, cell) => {
                this.updateData(e, cell);
              },
            },
          ]}
          ref={(ref) => (this.gridRepoRef = ref)}
          data={this.state.repoData}
          options={{
            pagination: "local",
            paginationSize: 15,
          }}
        />
        <ControlLabel className="errorLabel">{this.state.message}</ControlLabel>
        <div className="addnew-content">
          <form onSubmit={this.addNewRow}>
            <Row>
              <Col sm={3}>
                Repository Owner:
                <FormControl
                  className="input-box"
                  id="repoOwner"
                  type="input"
                  onChange={(e) =>
                    this.setState({ repositoryOwner: e.target.value })
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                Repository Name:
                <FormControl
                  className="input-box"
                  type="input"
                  id="folderName"
                  onChange={(e) =>
                    this.setState({ folderName: e.target.value })
                  }
                  required
                />
              </Col>
              {/* <Col sm={3}>
                S3 Path:
                <FormControl
                  className="input-box"
                  type="input"
                  onChange={(e) => this.setState({ s3Path: e.target.value })}
                />
              </Col> */}
              <Col sm={3}>
                <LoaderButton
                  block
                  bsClass="btn btn-outline-secondary"
                  disabled={this.state.isLoading}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Add New Repository"
                  loadingText="Adding Repository..."
                  className="addNewButton"
                />
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="managerepo-main">
          <Header props={this.props.props} history={this.props.history} />
          {this.renderRepoTabulator()}
        </div>
      </Fragment>
    );
  }
}

export default ManageRepository;
