import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function authenticatedroute({
  component: C,
  props: cProps,
  ...rest
}) {
  //console.log(cProps.props);
  return (
    <Route
      {...rest}
      render={(props) =>
        cProps.props.hasAuthenticated ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}
