import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl, Row, Col } from "react-bootstrap";
import "./search.css";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import config from "../../config";
import Popup from "reactjs-popup";
import Header from "../header";
import EmailPopup from "../emailpopup";
import BrowseFolderImage from "../Browse/browseFolderImage";
import FilePropertiesImage from "../Images/FileProperties.svg";
import { Auth } from "aws-amplify";
import LoaderButton from "../LoaderButton";
import ExpungementPopup from "../expungementpopup";
import FileProperties from "../Search/filePropertiesPopup";
import AppendPrependPdf from "../Browse/appenPrependPdf";
import {
  templateHasOcredFiles,
  getTemplates,
  getTemplate,
  searchTemplate,
  updateFileName,
  getUserProfile,
} from "../../libs/API";
import {
  DownloadIndexSvg,
  EmailSvgImage,
  RenameFileSvg,
  RenameFileSaveSvg,
  RenameFileCancelSvg,
  ExpungementSvg,
  UploadSvgImage,
} from "../Svg/allsvg";

// import TifImage from "../Images/tif.gif";
// import PdfImage from "../Images/pdf.gif";

const scoreColumnName = "Text Search Score";
class Search extends Component {
  gridSearchRef = null;
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      columns: [],
      selectedTemplate: "",
      fileSize: true,
      pageCount: true,
      pageDimensions: true,
      textScore: true,
      redactionColumn: true,
      templateColumnsToShow: [],
      search: {},
      results: [],
      viewerOpen: false,
      fileIdArray: [],
      showEmailPopup: false,
      ReadOnly: true,
      isLoading: false,
      showTextSearch: false,
      showExpungementPopup: false,
      showFilePropertiesPopup: false,
      client: "",
      showAppendPrependPopup: false,
    };
    this.openViewer = this.openViewer.bind(this);
    this.closeViewer = this.closeViewer.bind(this);
    this.viewFile = this.viewFile.bind(this);
    // console.log("search props", props);
  }

  async componentDidMount() {
    await this.loadTemplates();
    let clientName = "";
    if (this.props.props.hasCliftonWorkflow) {
      clientName = "clifton";
    } else if (this.props.props.hasMedfordWorkflow) {
      clientName = "medford";
    } else if (this.props.props.hasEnglewoodWorkflow) {
      clientName = "englewood";
    } else if (this.props.props.hasGlenrockWorkflow) {
      clientName = "glenrock";
    } else if (this.props.props.hasAberdeenWorkflow) {
      clientName = "Aberdeen";
    } else if (this.props.props.hasDemoExpungementWorkflow) {
      clientName = "Demo";
    } else if (this.props.props.hasEastamptonExpungementWorkflow) {
      clientName = "Eastampton";
    }
    this.setState({ client: clientName });
  }

  openViewer() {
    this.setState({ viewerOpen: true });
  }

  closeViewer() {
    this.setState({ viewerOpen: false });
  }

  getSelectedTemplateId() {
    const filtered = this.state.templates.filter((template) => {
      return template.tableName === this.state.selectedTemplate;
    });
    if (filtered.length > 0) {
      return filtered[0].templateId;
    }
  }

  async setShowTextSearch() {
    const selectedTemplateId = this.getSelectedTemplateId();
    if (!selectedTemplateId) {
      this.setState({ showTextSearch: false });
      return;
    }
    //check (via API) if OCR Status Table has OCRed files for selected template
    templateHasOcredFiles(selectedTemplateId).then((data) => {
      const templateHasOcredFiles = data === "true";
      const hasTextSearch = this.props.props.hasTextSearch;
      const showTextSearch = templateHasOcredFiles && hasTextSearch;
      this.setState({ showTextSearch });
    });
  }

  async loadTemplates() {
    getTemplates().then((data) => {
      if (data) {
        // console.log(`Available templates: ${data.map((t) => t.displayName.trim())}`);
        const templates = [
          {
            displayName: "Select Index Template",
            tableName: "Select Index Template",
          },
        ].concat(data);
        this.setState({ templates }, () => this.onLoadTemplates());
      }
    });
  }

  onTemplateChange = async (event) => {
    let options = event.target.options;
    let tableName = options[options.selectedIndex].value;
    if (tableName.length && tableName !== "Select Index Template") {
      this.setState({ ReadOnly: false });
      await this.loadSearchControls(tableName);
      await this.setShowTextSearch();
    } else {
      this.setState({
        columns: [],
        results: [],
        ReadOnly: true,
      });
    }
  };

  checkUserProfile = async (tableName) => {
    const userProfile = {
      userId: this.props.props.userId,
      templateName: tableName,
    };
    await getUserProfile(userProfile).then(async (x) => {
      if (x.data !== "") {
        //console.log(x, this.state.columns);
        // let array = this.state.actualColumns.slice();
        // if (x.data.templateColumnsToShow.length > 1) {
        //   for (let i = x.data.templateColumnsToShow.length - 1; i >= 0; i--) {
        //     array.splice(x.data.templateColumnsToShow[i], 1);
        //   }
        // }
        this.setState(
          {
            templateColumnsToShow: x.data.templateColumnsToShow,
            redactionColumn: x.data.redaction,
            fileSize: x.data.fileSize,
            textScore: x.data.textScore,
            pageDimensions: x.data.pageDimensions,
            pageCount: x.data.pageCount,
          },
          async () => {
            await this.searchTemplates(tableName);
          }
        );
      } else {
        await this.searchTemplates(tableName);
      }
    });
  };

  onLoadTemplates = async () => {
    //when the user has access to only one template, hide the first option and auto select the first index
    if (
      this.state &&
      this.state.templates &&
      this.state.templates.length === 2
    ) {
      const templateSelect = document.getElementById("templates");
      templateSelect.options[0].style.display = "none";
      templateSelect.selectedIndex = "1";
      templateSelect.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  async loadSearchControls(tableName) {
    //set current template and clear search object
    let hideParams = ["div.right", "div.renderToolTipButton"];
    this.hideTooTip(hideParams);
    this.setState({
      search: {},
      selectedTemplate: tableName,
      results: [],
      columns: [],
    });
    getTemplate(tableName).then((data) => {
      let columns = data;
      //console.log(`Template columns: ${columns.map((c) => c.InputName.trim())}`);
      this.setState({ columns });
    });
  }

  search = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let { selectedTemplate } = this.state;
    await this.checkUserProfile(selectedTemplate);
    // if (!search || Object.keys(search).length === 0) return;

    // if (this.props.props.hasManageRedaction) {
    //   this.gridSearchRef.table.addColumn({
    //     title: "Redacted",
    //     field: "HasAnnotations",
    //     formatter: this.boolFormatter,
    //   });
    // }
  };

  searchTemplates = async (selectedTemplate) => {
    let { search, columns } = this.state;
    const searchQueryString = Object.keys(search)
      .map((key) => `${key}=${search[key]}`)
      .join("&");
    searchTemplate(selectedTemplate, searchQueryString)
      .then((data) => {
        const searchResults = data;
        console.log(searchResults);
        //console.log(`Search results: ${JSON.stringify(data)}`);

        searchResults.forEach((searchResult) => {
          if (searchResult.HasAnnotations == null) {
            searchResult.HasAnnotations = false;
          }
        });
        //add/remove 'Text Search Score'
        if (searchResults.length > 0) {
          //if the search result has a 'Text Search Score' property and it's not in the list columns, then add it
          if (Object.keys(searchResults[0]).includes(scoreColumnName)) {
            if (!columns.find((col) => col.InputName === scoreColumnName)) {
              columns = columns.concat({
                InputName: scoreColumnName,
                DataType: "double",
                DataTypeLength: null,
              });
            }
          }
          //if the search result doesn't have the 'Text Search Score' property, remove it from columns if it exists
          else {
            if (columns.find((col) => col.InputName === scoreColumnName)) {
              columns = columns.filter(
                (col) => col.InputName !== scoreColumnName
              );
            }
          }
          this.setState({ columns });
        }
        this.setState(
          { results: searchResults, isLoading: false },
          function () {
            this.setGridFooterText(1);
            let gridcolumns = this.mapColumnsToGrid(false);
            this.gridSearchRef.table.setColumns(gridcolumns);
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
    let showParams = ["div.right", "div.renderToolTipButton"];
    this.showToolTip(showParams);
  };

  setGridFooterText(pageNumber, rowCount) {
    const resultsCountSpan = document.getElementById("resultsCount");

    if (resultsCountSpan && this.state.results) {
      let resultsCount = 0;
      if (rowCount == null) {
        resultsCount = this.state.results.length;
      } else {
        resultsCount = rowCount;
      }

      if (resultsCount < 1) {
        resultsCountSpan.innerText = "";
        return;
      }
      const pageSize = this.gridSearchRef.table.getPageSize();
      const pageFirst = (pageNumber - 1) * pageSize + 1;
      const pageLast = Math.min(pageNumber * pageSize, resultsCount);

      let text = "Result count: ";
      if (resultsCount > pageSize) {
        text += `${pageFirst} - ${pageLast} (of ${resultsCount})`;
      } else {
        text += `${pageLast}`;
      }
      resultsCountSpan.innerText = text;
    }
  }

  boolFormatter = function (cell) {
    return cell.getValue() === false ? "No" : "Yes";
  };

  mapColumnsToGrid = (renameFile) => {
    let dateFormatter = function (cell) {
      var value = cell.getValue();
      let dateString = cell.getRow().getData().Date;
      if (value && dateString) {
        if (value === dateString) {
          let date = new Date(value);
          value = getFormattedDate(date);
        }
      }
      return value;
    };

    function getFormattedDate(date) {
      const year = date.getUTCFullYear();
      let month = (date.getUTCMonth() + 1).toString();
      month = month.length > 1 ? month : "0" + month;
      let day = date.getUTCDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }

    let canBeLink = function (cell) {
      return renameFile
        ? cell.getValue()
        : "<a href=" + cell.getRow().getData() + ">" + cell.getValue() + "</a>";
    };

    let columns = []
      .concat({
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        titleFormatterParams: {
          rowRange: "visible",
        },
        // hozAlign: "center",
        width: "3%",
        download: false,
        headerSort: false,
        visible: !renameFile ? true : false,
        cellClick: function (e, cell) {
          cell.getRow().toggleSelect();
        },
      })
      .concat({
        title: "File Type",
        field: "FileExtension",
        headerSort: false,
        width: "3%",
        visible: false,
        download: true,
      })
      .concat({
        title: "FileId",
        field: "FileId",
        headerSort: false,
        width: "3%",
        visible: false,
        download: true,
      })
      .concat({
        title: "File Name",
        field: "FileName",
        formatter: canBeLink,
        editor: "input",
        cellClick: (e, cell) => {
          if (!renameFile) {
            this.viewFile(e, cell);
          }
        },
      })
      .concat(
        this.state.columns
          .map((c, i) => {
            let col = {
              title: c.InputName,
              field: c.InputName,
              formatter: dateFormatter,
              visible: !this.state.templateColumnsToShow.includes(i.toString()),
            };
            if (c.InputName === scoreColumnName) {
              col.tooltip = "Search Relevancy Score";
              col.visible = this.state.textScore;
              //col.formatter = "progress"; //todo - try out after scaling by 10 or 100...
            }
            return col;
          })
          .concat({
            title: "Redaction",
            field: "HasAnnotations",
            formatter: this.boolFormatter,
            visible:
              this.props.props.hasManageRedaction && this.state.redactionColumn,
          })
          .concat({
            title: "Page Count",
            field: "PageCount",
            visible: this.props.props.hasFileProperties && this.state.pageCount,
          })
          .concat({
            title: "File Size (Bytes)",
            field: "FileSize",
            formatter: function (cell, formatterParams) {
              if (cell.getValue() != null) {
                return cell.getValue().toLocaleString();
              } else {
                return cell.getValue();
              }
            },
            visible: this.props.props.hasFileProperties && this.state.fileSize,
          })
          .concat({
            title: "Page Dimensions",
            field: "PageDimensions",
            visible:
              this.props.props.hasFileProperties && this.state.pageDimensions,
          })
      );

    return columns;
  };

  handleSearchChange = (event) => {
    const { id, value } = event.target;
    // console.log("id, value", id, value);
    let search = this.state.search ?? {};
    if (!value || id === value) {
      delete search[id];
    } else {
      search[id] = value;
    }
    this.setState({ search: search });
  };

  async getRefreshToken() {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user.signInUserSession.refreshToken.token);
    return user.signInUserSession.refreshToken.token;
  }

  async viewFile(e, cell) {
    e.preventDefault();
    this.openViewer();
    const fileId = cell.getRow().getData().FileId;
    const fileName = cell.getRow().getData().FileName;
    const targetOrigin = config.ImageViewer;
    const refreshToken = await this.getRefreshToken();
    // console.log(refreshToken);
    const message = {
      refreshToken: refreshToken,
      fileId: fileId,
      editIndex: this.props.props.hasEditIndex,
      manageRedaction: this.props.props.hasManageRedaction,
      deletePages: this.props.props.hasDelete,
      textSearch: this.props.props.hasTextSearch
        ? this.state.search.textSearch
        : null,
      fileName: fileName,
      userEmailId: this.props.props.loggedInUser,
      managePii: this.props.props.hasPIIRole,
    };
    const imageViewer = document.getElementById("imageViewer");
    if (imageViewer.attachEvent) {
      imageViewer.attachEvent("onload", function () {
        imageViewer.contentWindow.postMessage(
          JSON.stringify(message),
          targetOrigin
        );
      });
    } else {
      imageViewer.onload = function () {
        imageViewer.contentWindow.postMessage(
          JSON.stringify(message),
          targetOrigin
        );
      };
    }
  }

  hideTooTip = (hideParams) => {
    Array.from(document.querySelectorAll(hideParams)).forEach(function (val) {
      val.style.display = "none";
    });
  };

  showToolTip = (showParams) => {
    Array.from(document.querySelectorAll(showParams)).forEach(function (val) {
      val.style.display = "block";
    });
  };

  handleReset = () => {
    let hideParams = [
      "div.right",
      "div.renderToolTipButton",
      "div.renameFileToolTipButton",
    ];
    this.hideTooTip(hideParams);
    let allInputs = document.querySelectorAll("input");
    if (allInputs.length > 0) {
      allInputs.forEach((input) => (input.value = ""));
    }
    let selectInput = document.querySelectorAll(".custom-select");
    if (selectInput) {
      selectInput.forEach((input) => (input.selectedIndex = 0));
    }
    this.setState({ search: {}, results: [] });
  };

  handleBrowse = () => {
    this.props.history.push("/browse");
  };

  handleChildUnmount = () => {
    this.setState({
      showEmailPopup: false,
      showExpungementPopup: false,
      showFilePropertiesPopup: false,
      showAppendPrependPopup: false,
    });
  };

  handleEmailPopup = () => {
    let rows = this.gridSearchRef.table.getSelectedData();
    if (rows.length > 0) {
      let fileIds = rows.map(function (m) {
        return { fileId: m.FileId, redaction: m.HasAnnotations };
      });
      this.setState({ showEmailPopup: true, fileIdArray: fileIds });
    }
  };

  handleExpungementPopup = () => {
    let rows = this.gridSearchRef.table.getSelectedData();
    if (rows.length === 1) {
      this.setState({ showExpungementPopup: true, fileIdArray: rows });
    } else if (rows.length > 1) {
      alert("You can expunge 1 file at a time.");
    }
  };

  handleFilePropertiesPopup = () => {
    this.setState({ showFilePropertiesPopup: true });
  };

  handleRenameFile = () => {
    // disabling the search button when the grid is in rename file view
    this.setState({ ReadOnly: true });
    let rows = this.gridSearchRef.table.getSelectedData();
    let hideParams = ["div.renderToolTipButton"];
    let showParams = ["div.renameFileToolTipButton"];
    if (rows.length > 0) {
      this.hideTooTip(hideParams);
      this.showToolTip(showParams);
      let gridcolumns = this.mapColumnsToGrid(true);
      this.gridSearchRef.table.setColumns(gridcolumns);
      this.gridSearchRef.table.setData(rows);
      this.setGridFooterText(1, rows.length);
    }
  };

  handleRenameFileCancel = (cancelFile) => {
    // enabling the search button when the user click cancel rename file.
    this.setState({ ReadOnly: false });
    let showParams = ["div.renderToolTipButton"];
    let hideParams = ["div.renameFileToolTipButton"];
    if (cancelFile === false) {
      let editedCells = this.gridSearchRef.table.getEditedCells();
      editedCells.forEach(function (cell) {
        let oldVal = cell.getOldValue();
        if (oldVal !== null) {
          try {
            cell.restoreOldValue();
          } catch {}
        }
      });
    }
    let gridcolumns = this.mapColumnsToGrid(false);
    this.gridSearchRef.table.setColumns(gridcolumns);
    this.gridSearchRef.table.setData(this.state.results);
    this.setGridFooterText(1);
    this.hideTooTip(hideParams);
    this.showToolTip(showParams);
  };

  handleRenameFileSave = async () => {
    let editedCells = this.gridSearchRef.table.getEditedCells();
    let mappedCells = [];
    if (editedCells.length > 0) {
      for (let i = 0; i < editedCells.length; i++) {
        let currentValue = editedCells[i].getValue().trim();
        let updateFileNameParams = {
          fileId: editedCells[i].getRow().getData().FileId,
          newfileName: currentValue,
        };
        mappedCells.push(updateFileNameParams);
      }
      await this.updateFileName(mappedCells);
    }
    // enabling the search button when the user click cancel rename file.
    this.setState({ ReadOnly: false });
  };

  updateFileName = async (renameFilesParams) => {
    updateFileName(renameFilesParams).then((data) => {
      if (data.status === 200) {
        this.handleRenameFileCancel(true);
      }
    });
  };

  handleDownloadIndexCsv = (e) => {
    e.preventDefault();
    const selectedTemplate = document.getElementById("templates");
    const selectedText =
      selectedTemplate.options[selectedTemplate.selectedIndex].text;
    let downloadName = `${selectedText}_IndexData`;
    this.gridSearchRef.table.download("csv", `${downloadName}.csv`, {
      delimiter: "|",
    });
  };

  renderToolTipButton() {
    return (
      <div style={{ width: "99%", height: "60px", maxHeight: "60px" }}>
        <div className="renderToolTipButton">
          <Row style={{ marginLeft: "5px" }}>
            <Col sm={8}>
              <img
                src={FilePropertiesImage}
                alt="FileProperties"
                title="Show/Hide Columns"
                style={{
                  width: "40px",
                  height: "37px",
                  cursor: "pointer",
                  marginLeft: "20px",
                }}
                onClick={this.handleFilePropertiesPopup}
              />
            </Col>
            <Col sm={4} className="text-right">
              {this.state.client.length > 0 && (
                <Button
                  title="Expunge Records"
                  bsSize="xsmall"
                  bsClass="btn btn-outline-default"
                  onClick={this.handleExpungementPopup}
                >
                  <ExpungementSvg />
                </Button>
              )}
              {this.props.props.hasManageFiles && (
                <Button
                  title="Rename Selected"
                  bsSize="xsmall"
                  bsClass="btn btn-outline-default"
                  onClick={this.handleRenameFile}
                >
                  <RenameFileSvg />
                </Button>
              )}
              {this.props.props.hasSendEmail && (
                <Button
                  title="Email Selected"
                  bsSize="xsmall"
                  bsClass="btn btn-outline-default"
                  onClick={this.handleEmailPopup}
                >
                  <EmailSvgImage />
                </Button>
              )}
              {this.props.props.hasDownloadIndex && (
                <Button
                  title="Download Index Data"
                  bsSize="xsmall"
                  bsClass="btn btn-outline-default"
                  onClick={this.handleDownloadIndexCsv}
                >
                  <DownloadIndexSvg />
                </Button>
              )}
              {this.props.props.hasManageFiles && (
                <Button
                  title="Upload Files"
                  bsSize="xsmall"
                  bsClass="btn btn-outline-default"
                  onClick={this.handleUploadFiles}
                >
                  <UploadSvgImage />
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="renameFileToolTipButton">
          <Button
            title="Save"
            bsSize="xsmall"
            bsClass="btn btn-outline-default"
            onClick={this.handleRenameFileSave}
          >
            <RenameFileSaveSvg />
          </Button>
          <Button
            title="Cancel"
            bsSize="xsmall"
            bsClass="btn btn-outline-default"
            onClick={() => this.handleRenameFileCancel(false)}
          >
            <RenameFileCancelSvg />
          </Button>
        </div>
      </div>
    );
  }

  renderSearchResults() {
    return (
      <Fragment>
        <ReactTabulator
          id="table"
          style={{
            maxHeight: "100%",
            width: "98%",
          }}
          columns={[]}
          data={this.state.results}
          index="FileId"
          ref={(ref) => (this.gridSearchRef = ref)}
          options={{
            pagination: "local",
            paginationSize: 20,
            placeholder: "No Files Available",
            downloadReady: (fileContents, blob) => blob,
          }}
          footerElement={<span id="resultsCount" style={{ float: "left" }} />}
          pageLoaded={(pageNumber) => {
            this.setGridFooterText(pageNumber);
          }}
        />
      </Fragment>
    );
  }

  renderEmailPop() {
    return (
      <Fragment>
        {this.state.showEmailPopup && (
          <EmailPopup
            props={{
              showEmailPopup: this.state.showEmailPopup,
              fileIdArray: this.state.fileIdArray,
              manageRedaction: this.props.props.hasManageRedaction,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderExpungementPopup() {
    return (
      <Fragment>
        {this.state.showExpungementPopup && (
          <ExpungementPopup
            props={{
              showExpungementPopup: this.state.showExpungementPopup,
              selectedRow: this.state.fileIdArray,
              client: this.state.client,
              templateName: this.state.selectedTemplate,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderAppendPrependFilePopup() {
    return (
      <Fragment>
        {this.state.showAppendPrependPopup && (
          <AppendPrependPdf
            props={{
              filePath: this.state.fileName,
              fileIdArray: this.state.fileIdArray,
              showAppendPrependPopup: this.state.showAppendPrependPopup,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  handleUploadFiles = async (e) => {
    e.preventDefault();
    const rows = this.gridSearchRef.table.getSelectedData();
    if (rows.length > 1) {
      alert("You can Append or Prepend 1 file at a time.");
      return;
    }
    if (rows.length === 1) {
      this.setState({ showAppendPrependPopup: true, fileIdArray: rows });
      return;
    }
  };

  renderFilePropertiesPopup() {
    return (
      <Fragment>
        {this.state.showFilePropertiesPopup && (
          <FileProperties
            props={{
              showFilePropertiesPopup: this.state.showFilePropertiesPopup,
              columns: this.state.columns,
              userId: this.props.props.userId,
              templateName: this.state.selectedTemplate,
              hasManageRedaction: this.props.props.hasManageRedaction,
              hasTextScore: this.props.props.hasTextSearch,
              hasFileProperties: this.props.props.hasFileProperties,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div id="mainPageDiv">
          <Header props={this.props.props} history={this.props.history} />
          <div className="SearchDocumentSync">
            <div className="left">
              <FormGroup>
                <Button
                  title="Browse Repository"
                  bsClass="btn btn-outline-secondary"
                  onClick={this.handleBrowse}
                >
                  <BrowseFolderImage />
                </Button>
              </FormGroup>
              <FormGroup controlId="templates">
                {/* <ControlLabel>Index Template:</ControlLabel>{" "} */}
                <FormControl
                  componentClass="select"
                  onChange={this.onTemplateChange}
                  className="indexTemplateselect"
                >
                  {this.state.templates.map((template) => (
                    <option key={template.tableName} value={template.tableName}>
                      {template.displayName.trim()}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
              <form
                onSubmit={this.search}
                id="searchControls"
                autoComplete="off"
              >
                {this.state.columns
                  .filter((x) => x.InputName !== scoreColumnName)
                  .map((column) => (
                    <FormGroup key={column.InputName}>
                      {/* {
                        <label>{column.InputName}:</label>
                        console.log(column)
                      } */}
                      {column.ControlledList ? (
                        <select
                          id={column.InputName}
                          onChange={this.handleSearchChange}
                          className="custom-select"
                        >
                          {[`${column.InputName}`]
                            .concat(column.ControlledList.sort())
                            .map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <div>
                          {column.DataType === "date" ? (
                            <div>
                              <Row>
                                <Col sm={5}>
                                  <span className="static-label">
                                    {column.InputName} From:
                                  </span>
                                </Col>
                                <Col sm={7}>
                                  <FormControl
                                    id={"fromDate_" + column.InputName}
                                    onChange={this.handleSearchChange}
                                    placeholder={column.InputName}
                                    className="input-box date"
                                    type="date"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={5}>
                                  <span className="static-label">
                                    {column.InputName} To:
                                  </span>
                                </Col>
                                <Col sm={7}>
                                  <FormControl
                                    id={"toDate_" + column.InputName}
                                    onChange={this.handleSearchChange}
                                    placeholder={column.InputName}
                                    className="input-box date"
                                    type="date"
                                  />
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div>
                              <FormControl
                                id={column.InputName}
                                onChange={this.handleSearchChange}
                                placeholder={column.InputName}
                                className="input-box"
                                type="input"
                              />
                              <span className="floating-label">
                                {column.InputName}:
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </FormGroup>
                  ))}
                {this.state.showTextSearch && (
                  <div>
                    <FormGroup controlId="textSearch">
                      <FormControl
                        onChange={this.handleSearchChange}
                        type="text"
                        readOnly={this.state.ReadOnly}
                        placeholder="Text Search"
                        style={{ color: "#9e0203" }}
                        className="input-box"
                      ></FormControl>
                      <span className="floating-label">Text search:</span>
                    </FormGroup>
                  </div>
                )}
                <FormGroup>
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.ReadOnly}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Search"
                    loadingText="Searching..."
                    className="searchResetbutton"
                  />
                  <Button
                    bsClass="btn btn-outline-secondary"
                    type="button"
                    className="searchResetbutton"
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>
                </FormGroup>
              </form>
            </div>
            {this.renderToolTipButton()}
            <div className="right">{this.renderSearchResults()}</div>
          </div>
        </div>
        <Popup
          contentStyle={{
            width: "95%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.viewerOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeViewer}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closeViewer}>
              &times;
            </a>
            <iframe
              id="imageViewer"
              src={config.ImageViewer}
              title="Image Viewer"
              style={{ width: "100%", height: "90vh" }}
            ></iframe>
          </div>
        </Popup>
        {this.renderEmailPop()}
        {this.renderExpungementPopup()}
        {this.renderFilePropertiesPopup()}
        {this.renderAppendPrependFilePopup()}
      </Fragment>
    );
  }
}

export default Search;
