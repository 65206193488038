import React, { Component } from "react";
import Popup from "reactjs-popup";
import {
  Button,
  FormGroup,
  Checkbox,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import {
  listGroups,
  updateGroups,
  getSavedRestrictedIps,
} from "../../libs/API";

class UpdateGroupsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: this.props.props.showUpdateGroupsPopup,
      userEmail: this.props.props.email,
      username: this.props.props.username,
      groups: this.props.props.groups,
      fullGroupList: [],
      ipAccess: "",
    };
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    this.loadGroups();
  }

  async getSavedRestrictedIp() {
    let listGroup = this.props.props.groups;
    getSavedRestrictedIps(this.props.props.email).then((data) => {
      this.setState({ ipAccess: data }, () => {
        if (listGroup.find((x) => x.includes("RestrictAccessByIP"))) {
          let restrictIpTextBox = document.getElementsByName(
            `textboxDocumentSync_RestrictAccessByIP`
          );
          restrictIpTextBox[0].style.display = "block";
        }
      });
    });
  }

  async loadGroups() {
    const data = await listGroups();
    let groups = data;

    // Define exclusion lists for each client
    const exclusions = {
      Clifton: [
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Medford: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Englewood: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Glenrock: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Aberdeen: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Demo: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
      Eastampton: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
      ],
      None: [
        "DocumentSync_CliftonExpungementWorkflow",
        "DocumentSync_MedfordExpungementWorkflow",
        "DocumentSync_EnglewoodExpungementWorkflow",
        "DocumentSync_GlenrockExpungementWorkflow",
        "DocumentSync_AberdeenExpungementWorkflow",
        "DocumentSync_DemoExpungementWorkflow",
        "DocumentSync_EastamptonExpungementWorkflow",
      ],
    };

    // Get the exclusion list for the current client, or an empty array if none exists
    const client = this.props.props.client ?? "None";
    const excludeGroups = exclusions[client] || [];

    // Filter the groups
    groups = groups.filter((item) => !excludeGroups.includes(item));

    // Update the fullGroupList in the state
    const filteredGroups = this.state.fullGroupList.concat(
      groups.filter(
        (group) =>
          !group.includes("FoveonicsAdmin") &&
          !group.includes("OPRA_Administrator")
      )
    );

    const fullGroupList = this.state.fullGroupList
      .concat(filteredGroups)
      .sort((a, b) => a.localeCompare(b));

    this.setState({ fullGroupList }, () => this.getSavedRestrictedIp());
  }

  closePopup() {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  }

  handleChange = (event) => {
    const id =
      event.target.type === "radio" || event.target.type === "checkbox"
        ? event.target.name
        : event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let restrictIpTextBox = document.getElementsByName(
      `textbox${event.target.name}`
    );
    if (event.target.name.includes("RestrictAccessByIP") && value) {
      restrictIpTextBox[0].style.display = "block";
    } else {
      restrictIpTextBox[0].style.display = "none";
    }
    let { groups } = this.state;

    if (value) {
      groups.push(id);
    } else {
      groups = groups.filter((group) => group !== id);
    }

    this.setState({ groups });
  };

  handleUpdateGroups = async (e) => {
    e.preventDefault();
    const { username, groups, userEmail, ipAccess } = this.state;
    await updateGroups({ username, groups, userEmail, ipAccess });
    this.props.updateGroups(username, groups);
    this.closePopup();
  };

  formatGroupName = (groupName) => {
    if (groupName === "DocumentSync_OCR" || groupName === "DocumentSync_PII") {
      return groupName.replace(/^DocumentSync_/, "");
    } else if (groupName === "DocumentSync_RestrictAccessByIP") {
      return groupName
        .replace(/^DocumentSync_/, "")
        .replace(/(?<=[a-z])(?=[A-Z0-9])/g, " ");
    } else {
      return groupName
        .replace(/^DocumentSync_/, "")
        .replace(/([A-Z])(?=[a-z0-9])/g, " $1")
        .trim();
    }
  };

  render() {
    const { popupOpen, userEmail, groups, fullGroupList } = this.state;
    return (
      <Popup
        contentStyle={{
          width: "20%",
          borderRadius: "15px",
          padding: "15px",
        }}
        open={popupOpen}
        modal
        closeOnDocumentClick={false}
        onClose={this.closePopup}
      >
        <div>
          {/* eslint-disable-next-line */}
          <a className="close" onClick={this.closePopup}>
            &times;
          </a>
          <div>
            <h4>Update Roles</h4>
            <hr />
            <div style={{ marginBottom: "10px" }}>
              <b>Email:</b> {userEmail}
            </div>

            <form onSubmit={this.handleUpdateGroups}>
              <FormGroup>
                <ControlLabel>
                  <b>Roles:</b>
                </ControlLabel>
                <div>
                  {fullGroupList.map((groupName, i) => (
                    <span key={i}>
                      <Checkbox
                        key={i}
                        name={`${groupName}`}
                        inline
                        onChange={this.handleChange}
                        checked={groups.includes(groupName)}
                      >
                        {"   " + this.formatGroupName(groupName)}
                      </Checkbox>
                      <FormControl
                        componentClass="input"
                        name={`textbox${groupName}`}
                        onChange={(e) =>
                          this.setState({ ipAccess: e.target.value })
                        }
                        value={this.state.ipAccess}
                        type="text"
                        autoComplete="off"
                        style={{ display: "none" }}
                      ></FormControl>
                      <br />
                    </span>
                  ))}
                </div>
              </FormGroup>
              <FormGroup>
                <Button bsClass="btn btn-outline-secondary" type="submit">
                  Update Roles
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      </Popup>
    );
  }
}

export default UpdateGroupsPopup;
