import React from "react";
import Login from "./../Login/login";
import Search from "./../Search/search";
import HomePageRoute from "./homePageRoute";
import AuthenticatedRoute from "./authenticatedRoute";
import Browse from "../Browse/browse";
import MyAccount from "../MyAccount/myaccount";
import ManageUsers from "../ManageUsers/manageusers";
import ManageRepository from "../ManageRepository/managerepository";
import ManageIndexTemplate from "../ManageIndexTemplate/manageindextemplate";
import ResetPassword from "../ResetPassword/resetpassword";
import OcrIndexTempates from "../Ocr/ocrindextempates";

class RoutePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <HomePageRoute
          path="/"
          exact
          component={Login}
          props={this.props}
        ></HomePageRoute>
        <HomePageRoute
          path="/login/reset"
          exact
          component={ResetPassword}
          props={this.props}
        ></HomePageRoute>
        <AuthenticatedRoute
          path="/search"
          exact
          component={Search}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/browse"
          exact
          component={Browse}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/myaccount"
          exact
          component={MyAccount}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/manageusers"
          exact
          component={ManageUsers}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/managerepository"
          exact
          component={ManageRepository}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/manageindextemplate"
          exact
          component={ManageIndexTemplate}
          props={this.props}
        ></AuthenticatedRoute>
        <AuthenticatedRoute
          path="/ocrindextempates"
          exact
          component={OcrIndexTempates}
          props={this.props}
        ></AuthenticatedRoute>
      </div>
    );
  }
}

export default RoutePage;
