import React, { Component, Fragment } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { ReactTabulator } from "react-tabulator";
import Header from "../header";
import CreateNewUserSvg from "./createnewusersvg";
import CreateUserPopup from "./createuserpopup";
import UpdateGroupsPopup from "./updateGroups";
import UpdateReposPopup from "./updateRepos";
import {
  listGroupsForUser2,
  listReposForUser,
  listUsersDynamo,
  deleteUser,
} from "../../libs/API";
import "./manageusers.css";
import { DeleteFileSvg } from "../Svg/allsvg";
import Popup from "reactjs-popup";

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateUserPopup: false,
      showUpdateGroupsPopup: false,
      showUpdateReposPopup: false,
      deleteUserPopupOpen: false,
      users: [],
      email: "",
      username: "",
      groups: [],
      repos: [],
      client: "",
    };
    this.ref = null;

    this.handleEditGroups = this.handleEditGroups.bind(this);
    this.hideUpdateGroups = this.hideUpdateGroups.bind(this);
    this.showUpdateGroups = this.showUpdateGroups.bind(this);
    this.hideUpdateRepos = this.hideUpdateRepos.bind(this);
    this.showUpdateRepos = this.showUpdateRepos.bind(this);
  }

  async componentDidMount() {
    await this.populateUsers();
  }

  async populateUsers() {
    listUsersDynamo().then(async (data) => {
      const users = data.users;
      const client = data.client;
      this.setState({ users, client });
      this.ref.table.setData(data.users);
    });
  }

  async loadGroups(user) {
    const { username } = user;
    listGroupsForUser2(username).then((data) => {
      const groups = data;
      if (!groups.includes("DocumentSync")) {
        this.ref.table.deleteRow(username);
      } else {
        const rowData = this.ref.table.getRow(username).getData();
        rowData.groups = groups;
        this.ref.table.updateOrAddData([rowData]);
      }
    });
  }

  handleChildUnmount = () => {
    this.setState({ showCreateUserPopup: false });
  };

  handleNewRowInUserList = (addNewRow) => {
    let userList = this.state.users;
    userList.unshift(addNewRow);
    this.setState({ users: userList });
    this.ref.table.addRow(addNewRow, true);
  };

  handleCreateUser = () => {
    this.setState({ showCreateUserPopup: true });
  };

  showUpdateGroups() {
    this.setState({ showUpdateGroupsPopup: true });
  }

  async hideUpdateGroups() {
    this.setState({ showUpdateGroupsPopup: false });
  }

  updateGroups = (username, groups) => {
    const rowData = this.ref.table.getRow(username).getData();
    rowData.groups = groups;
    this.ref.table.updateOrAddData([rowData]);
  };

  showUpdateRepos() {
    this.setState({ showUpdateReposPopup: true });
  }

  async hideUpdateRepos() {
    this.setState({ showUpdateReposPopup: false });
  }

  groupFormatter(cell) {
    const groupArray = cell._cell.value;
    //filter and map to just display names of groups that have relevant DS roles
    const formattedNames = groupArray
      .filter((g) => g.startsWith("DocumentSync_"))
      .map(function (g) {
        const groupName = g.split("_")[1];
        if (groupName === "OCR" || groupName === "PII") {
          return groupName;
        } else if (groupName === "RestrictAccessByIP") {
          return "Restrict Access By IP";
        } else {
          // Add space between camelCase words
          return groupName.replace(/([a-z])([A-Z])/g, "$1 $2");
        }
      })
      .join(", ");
    return formattedNames;
  }

  handleEditGroups(e, row) {
    e.preventDefault();
    const rowData = row._row.data;
    this.setState({
      email: rowData.userEmail,
      groups: rowData.groups,
      username: rowData.username,
    });
    this.showUpdateGroups();
  }

  async updateRepos(e, row) {
    e.preventDefault();
    const rowData = row._row.data;
    await listReposForUser(rowData.userEmail).then((data) => {
      const rowData1 = this.ref.table.getRow(rowData.username).getData();
      rowData.repos = data;
      this.ref.table.updateOrAddData([rowData1]);
    });
    this.setState(
      {
        email: rowData.userEmail,
        repos: rowData.repos,
        username: rowData.username,
      },
      () => {
        this.showUpdateRepos();
      }
    );
  }

  renderDeleteUserPopup() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "35px",
          }}
          open={this.state.deleteUserPopupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Delete User</h5>
              <div>
                <FormGroup>Are you sure to delete the user(s)</FormGroup>
                <FormGroup>
                  <Button
                    bsSize="small"
                    bsClass="btn btn-outline-secondary"
                    onClick={this.handleDeleteUsers}
                  >
                    Yes
                  </Button>
                </FormGroup>
              </div>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }

  openDeleteUserPopup = (e) => {
    e.preventDefault();
    const rows = this.ref.table.getSelectedData();
    if (rows.length >= 1) {
      this.setState({ deleteUserPopupOpen: true });
    }
  };

  handleDeleteUsers = (e) => {
    e.preventDefault();
    const rows = this.ref.table.getSelectedData();
    if (rows.length >= 1) {
      let users = [];
      for (let i = 0; i < rows.length; i++) {
        let Params = {
          username: rows[i].username,
          userEmail: rows[i].userEmail,
          groups: rows[i].groups,
        };
        users.push(Params);
      }
      deleteUser(users).then((data) => {
        if (data.status === 200) {
          let userList = this.state.users;
          let filteredList = userList.filter((x) => {
            return !users.some((y) => y.userEmail === x.userEmail);
          });
          this.setState({ users: filteredList });
          this.ref.table.setData(filteredList);
          this.closePopup();
        }
      });
    }
  };

  closePopup = () => {
    this.setState({ deleteUserPopupOpen: false });
  };

  render() {
    const {
      showCreateUserPopup,
      showUpdateGroupsPopup,
      showUpdateReposPopup,
      email,
      username,
      groups,
      repos,
      client,
    } = this.state;

    return (
      <div className="manageusers-main">
        <Header props={this.props.props} history={this.props.history} />
        <div className="manageusers-content">
          <h4> Manage Users</h4>
          <br />
          <Button
            title="Delete User"
            bsSize="xsmall"
            bsClass="btn btn-outline-secondary"
            className="Delete-UserButton"
            onClick={this.openDeleteUserPopup}
          >
            <DeleteFileSvg />
          </Button>
          <Button
            title="Create a New User"
            bsSize="xsmall"
            bsClass="btn btn-outline-secondary"
            className="Add-UserButton"
            onClick={this.handleCreateUser}
          >
            <CreateNewUserSvg />
          </Button>
          <div id="listUsers">
            <ReactTabulator
              ref={(ref) => (this.ref = ref)}
              index="username"
              columns={[
                {
                  formatter: "rowSelection",
                  titleFormatter: "rowSelection",
                  titleFormatterParams: {
                    rowRange: "visible",
                  },
                  width: "2%",
                  headerSort: false,
                  visible: true,
                  cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                  },
                },
                {
                  field: "userEmail",
                  title: "Email",
                  width: "15%",
                  headerFilter: true,
                },
                {
                  field: "groups",
                  title: "Roles",
                  formatter: this.groupFormatter,
                  width: "70%",
                },
                {
                  width: "88",
                  formatter: () => {
                    return "<button>Edit Roles</button>";
                  },
                  cellClick: (e, cell) => {
                    this.handleEditGroups(e, cell.getRow());
                  },
                },
                {
                  width: "92",
                  formatter: () => {
                    return "<button>Edit Repos</button>";
                  },
                  cellClick: async (e, cell) => {
                    await this.updateRepos(e, cell.getRow());
                  },
                },
              ]}
              data={[]}
              //rowDblClick={this.updateGroups}
              options={{
                pagination: "local",
                paginationSize: 10,
                paginationSizeSelector: [10, 20, 50, 100],
                placeholder: "Loading...",
              }}
            />
          </div>
          {showCreateUserPopup && (
            <CreateUserPopup
              props={{ showCreateUserPopup }}
              unmountMe={this.handleChildUnmount}
              handleNewRowInUserList={this.handleNewRowInUserList}
            />
          )}
          {showUpdateGroupsPopup && (
            <UpdateGroupsPopup
              props={{ showUpdateGroupsPopup, email, username, groups, client }}
              unmountMe={this.hideUpdateGroups}
              updateGroups={this.updateGroups}
            />
          )}
          {showUpdateReposPopup && (
            <UpdateReposPopup
              props={{ showUpdateReposPopup, email, username, repos }}
              unmountMe={this.hideUpdateRepos}
            />
          )}
          {this.renderDeleteUserPopup()}
        </div>
      </div>
    );
  }
}

export default ManageUsers;
